import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as GlobeSVG } from "../../../images/GlobeHemisphereEast.svg";
import { ReactComponent as GearSVG } from "../../../images/GearSix.svg";
import { ReactComponent as ChartBarSVG } from "../../../images/ChartBar.svg";
import { ReactComponent as CellTowerSVG } from "../../../images/CellTower.svg";

export const AdvantagesEnum = [
   {
      Icon: GlobeSVG,
      heading: "Engineered and made in Europe",
   },
   {
      Icon: GearSVG,
      heading: "Best in class service and support",
   },
   {
      Icon: ChartBarSVG,
      heading: "Business intelligence",
   },
   {
      Icon: CellTowerSVG,
      heading: "4G connectivity in one click",
   },
];

const Advantage = ({ Icon, heading }) => (
   <div className={styles.card}>
      <Icon />
      <p>{heading}</p>
   </div>
);

export default function AdvantagesCardsSection() {
   return (
      <div className={styles.container}>
         {AdvantagesEnum.map((adv) => (
            <Advantage {...adv} />
         ))}
      </div>
   );
}
