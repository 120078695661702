import React from "react";
import "./index.css";

export const ButtonTypes = {
   PRIMARY: "PRIMARY",
   SECONDARY: "SECONDARY",
};

const Button = ({
   children,
   variant = ButtonTypes.PRIMARY,
   onClick,
   type,
   disabled,
}) => {
   const buttonClass =
      variant === ButtonTypes.PRIMARY ? "primaryButton" : "outlineButton";

   return (
      <button
         className={`${"buttonBase"} ${buttonClass} ${disabled ? "disabled" : ""}`}
         onClick={onClick}
         type={type}
      >
         {children}
      </button>
   );
};

export default Button;
