import React, { useState } from "react";
import styles from "./styles.module.css";
import LandingContent from "./components/sections/landingContent";
import Advantages from "./components/sections/advantages";
import AdvantagesCardsSection from "./components/sections/cardsSection";
import AboutCard from "./components/sections/aboutCard";
import ContactSection from "./components/sections/contactSection";
import Footer from "./components/sections/footer";
import VideoLightBox from "./components/videoLightBox";

export default function Homepage() {
   const [isVideoLightBoxOpen, setIsVideoLightBoxOpen] = useState(false);

   const handleTooglePopup = () => setIsVideoLightBoxOpen(!isVideoLightBoxOpen);
   return (
      <div className={styles.mainContainer}>
         <LandingContent handleOpenPopup={handleTooglePopup} />
         <div id="mainSection" className={styles.bottomContainer}>
            <div className={styles.innerContainer}>
               <Advantages />
               <AdvantagesCardsSection />
               <AboutCard handleOpenPopup={handleTooglePopup} />
               <ContactSection />
               <Footer />
            </div>
         </div>
         {isVideoLightBoxOpen && (
            <VideoLightBox handleClosePopup={handleTooglePopup} />
         )}
      </div>
   );
}
