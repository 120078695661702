import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Button, { ButtonTypes } from "../../button";
import CustomTextField from "../../textField";
import { ReactComponent as CheckSVG } from "../../../images/check.svg";
import emailjs from "@emailjs/browser";

const EmailSentConfirmation = () => {
   return (
      <div className={styles.emailSentContainer}>
         <CheckSVG />
         <p className="body-md-bold">Thank you for reaching out.</p>
         <p>Our team will respond within the next 24 hours.</p>
      </div>
   );
};

export default function ContactSection() {
   const [formData, setFormData] = useState({
      name: "",
      email: "",
      sector: "",
      message: "",
   });
   const [errors, setErrors] = useState({});
   const [isEmailSent, setIsEmailSent] = useState(false);
   const [isFormValid, setIsFormValid] = useState(false);

   const handleChange = (e) => {
      const { name, value } = e.target;

      setFormData({ ...formData, [name]: value });

      const newErrors = { ...errors };
      if (name === "name" && !value.trim()) {
         newErrors.name = "Name is required";
      } else if (name === "email") {
         if (!value.trim()) {
            newErrors.email = "Email is required";
         } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            newErrors.email = "Please enter a valid email address";
         } else {
            delete newErrors.email;
         }
      } else if (name === "message" && !value.trim()) {
         newErrors.message = "Message is required";
      } else {
         delete newErrors[name];
      }
      setErrors(newErrors);
   };

   useEffect(() => {
      const isValid =
         Object.values(errors).every((error) => !error) &&
         formData.name.trim() &&
         formData.email.trim() &&
         /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) &&
         formData.message.trim();
      setIsFormValid(isValid);
   }, [formData, errors]);

   const sendEmail = (e) => {
      e.preventDefault();

      if (!isFormValid) {
         return; // Stop submission if validation fails
      }

      const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
      const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
      const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

      emailjs.send(serviceID, templateID, formData, publicKey).then(
         () => {
            setIsEmailSent(true);
         },
         (err) => {
            console.error("FAILED...", err);
         },
      );
   };

   return (
      <div id="contactSection" className={styles.container}>
         <div className={styles.headingContainer}>
            <p className="tagline-text">Interested?</p>
            <h2>Get in touch</h2>
            <p>
               Fill the form below and get a response from our team in 24 hours.
            </p>
         </div>
         <div className={styles.bottomContainer}>
            <div className={styles.leftContainer}>
               {isEmailSent ? (
                  <EmailSentConfirmation />
               ) : (
                  <form onSubmit={sendEmail} className={styles.formContainer}>
                     <CustomTextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        handleChange={handleChange}
                        error={!!errors.name}
                        // helperText={errors.name}
                     />
                     <CustomTextField
                        type="email"
                        name="email"
                        label="Email"
                        value={formData.email}
                        handleChange={handleChange}
                        error={!!errors.email}
                        // helperText={errors.email}
                     />
                     <CustomTextField
                        name="sector"
                        label="Sector (optional)"
                        value={formData.sector}
                        handleChange={handleChange}
                     />
                     <CustomTextField
                        name="message"
                        label="Message"
                        value={formData.message}
                        handleChange={handleChange}
                        multiline
                        error={!!errors.message}
                        // helperText={errors.message}
                     />
                     <Button
                        variant={ButtonTypes.PRIMARY}
                        type="submit"
                        disabled={!isFormValid}
                     >
                        Send message
                     </Button>
                  </form>
               )}
            </div>
            <div className={styles.rightContainer}>
               <div className={styles.contactDetail}>
                  <h4>Reach out</h4>
                  <p>sales@lizarsystems.com</p>
               </div>
               <div className={styles.contactDetail}>
                  <h4>LIZAR SYSTEMS HQ</h4>
                  <p>
                     R. Soeiro Pereira Gomes Lote 1,1600-198 Lisboa, Portugal
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
}
