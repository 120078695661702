import "./global.css";
import Homepage from "./homepage";

function App() {
   return (
      <div className="App">
         <Homepage />
      </div>
   );
}

export default App;
