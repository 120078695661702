import React from "react";
import TextField from "@mui/material/TextField";

export default function CustomTextField({
   label,
   value,
   handleChange,
   multiline = false,
   rows = 4, // Number of rows for textarea
   type,
   error,
   helperText,
   name,
}) {
   return (
      <TextField
         id="custom-textfield"
         label={label}
         type={type}
         variant="filled"
         name={name}
         value={value}
         onChange={handleChange}
         multiline={multiline} // Enables textarea when true
         rows={multiline ? rows : undefined} // Sets rows for textarea
         error={error} // Highlight field on error
         helperText={helperText} // Display error message
         sx={{
            width: "100%",
            "& .MuiInputBase-root": {
               color: "white", // Text color
               backgroundColor: "var(--bg-white-05)", // Dark background
               borderRadius: "3px",
               border: `1px solid ${error ? "red" : "#FFFFFF33"}`, // Red border on error
               transition: "border-color 0.1s ease", // Smooth transition for border
            },
            "& .MuiInputBase-root:hover": {
               border: `1px solid ${error ? "red" : "#FFFFFF80"}`, // Keep hover behavior with error
            },
            "& .MuiInputLabel-root": {
               color: "var(--text-placeholder)", // Label color
            },
            "& .MuiInputLabel-root.Mui-focused": {
               color: "var(--text-placeholder)", // Label color when focused
            },
            "& .MuiInputBase-root.Mui-focused": {
               border: `1px solid ${error ? "red" : "var(--text-primary)"}`, // Focus border with error
            },
            // Remove bottom border in all states
            "& .MuiFilledInput-root:before, & .MuiFilledInput-root:hover:before":
               {
                  borderBottom: "none !important", // Ensure no bottom border appears
               },
            "& .MuiFilledInput-root:after": {
               borderBottom: "none !important", // Remove the focus bottom border
            },
         }}
      />
   );
}
