import React, { useEffect } from "react";
import lizardVideo from "../../videos/lizard.mp4";
import styles from "./styles.module.css";
import { ReactComponent as CloseSVG } from "../../images/closeLightBox.svg";

export default function VideoLightBox({ handleClosePopup }) {
   useEffect(() => {
      // Disable scroll on mount
      document.body.style.overflow = "hidden";

      // Re-enable scroll on unmount
      return () => {
         document.body.style.overflow = "auto";
      };
   }, []);

   return (
      <div
         className={styles.overlay}
         onClick={(e) => {
            if (e.target === e.currentTarget) {
               handleClosePopup();
            }
         }}
      >
         <CloseSVG onClick={handleClosePopup} className={styles.closeButton} />

         <video controls autoPlay className={styles.video}>
            <source src={lizardVideo} type="video/mp4" />
            Your browser does not support the video tag.
         </video>
      </div>
   );
}
