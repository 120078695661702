import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { ReactComponent as SpeedSVG } from "../../../images/Speedometer.svg";
import { ReactComponent as BarbellSVG } from "../../../images/Barbell.svg";
import { ReactComponent as RocketSVG } from "../../../images/RocketLaunch.svg";
import { ReactComponent as ShieldSVG } from "../../../images/ShieldCheckered.svg";
import { ReactComponent as HeadCircuitSVG } from "../../../images/head-circuit-duotone.svg";
import ComputerImg1 from "../../../images/lizardComputer1.webp";
import ComputerImg2 from "../../../images/lizardComputer2.webp";
import ComputerImg3 from "../../../images/lizardComputer3.webp";
import ComputerImg4 from "../../../images/lizardComputer4.webp";
import ComputerImg5 from "../../../images/lizardComputer5.webp";
import { useIsMobile } from "../../../hooks/isMobile";

export const AdvantagesEnum = [
   {
      Icon: HeadCircuitSVG,
      heading: "AI-driven tools",
      advantages: [
         {
            title: "Quality control & Security",
            description:
               "Setting the standard with advanced quality control, robust access protocols to prevent unauthorized use, and remote machine maintenance powered by AI-driven tools developed by our experts team",
         },
      ],
   },
   {
      Icon: SpeedSVG,
      heading: "High Speed & Quality",
      advantages: [
         {
            title: "20W Fiber Laser Technology",
            description:
               "Ultra-fast precision engraving with professional-grade precision",
         },
      ],
   },
   {
      Icon: BarbellSVG,
      heading: "Compact & Robust Design",
      advantages: [
         {
            title: "Built for tough environments",
            description:
               "Compact and durable, ideal for decentralized issuance and demanding conditions",
         },
      ],
   },
   {
      Icon: RocketSVG,
      heading: "One-stop shop",
      advantages: [
         {
            title: "Easy deployment",
            description:
               "Plug-and-play setup for rapid field deployment without complex configurations",
         },
         {
            title: "Seamless integration",
            description:
               "Software integration and specific development for ID systems",
         },
      ],
   },
   {
      Icon: ShieldSVG,
      heading: "Remote & Secure",
      advantages: [
         {
            title: "Personalization",
            description:
               "Instant personalization and issuance with first-class remote MDM",
         },
         {
            title: "Control & security",
            description:
               "Real-time remote control with advanced security for updates and maintenance, GAFA-Free",
         },
      ],
   },
];

const Advantage = React.forwardRef(
   ({ Icon, heading, advantages, isActive }, ref) => {
      return (
         <div
            ref={ref}
            className={`${styles.advantageWrapper} ${isActive ? styles.active : ""}`}
         >
            <div className={styles.topAdvantage}>
               <Icon />
               <h3>{heading}</h3>
            </div>
            <div className={styles.bottomAdvantage}>
               {advantages.map((adv, index) => (
                  <div key={index} className={styles.featureWrapper}>
                     <p className="body-md-semibold">{adv.title}</p>
                     <p className="body-md-regular">{adv.description}</p>
                  </div>
               ))}
            </div>
         </div>
      );
   },
);

export default function Advantages() {
   const [activeIndex, setActiveIndex] = useState(0);
   const cardRefs = useRef([]);
   const isMobile = useIsMobile();

   useEffect(() => {
      const handleScroll = () => {
         const center = window.innerHeight / 2;
         let closest = 0;
         let closestDistance = Infinity;

         cardRefs.current.forEach((ref, index) => {
            if (ref) {
               const rect = ref.getBoundingClientRect();
               const distance = Math.abs(rect.top + rect.height / 2 - center);
               if (distance < closestDistance) {
                  closest = index;
                  closestDistance = distance;
               }
            }
         });

         setActiveIndex(closest);
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check

      return () => window.removeEventListener("scroll", handleScroll);
   }, []);

   const getComputerImage = () => {
      switch (activeIndex) {
         case 0:
            return ComputerImg5;
         case 1:
            return ComputerImg1;
         case 2:
            return ComputerImg2;
         case 3:
            return ComputerImg3;
         case 4:
            return ComputerImg4;
         default:
            return ComputerImg5;
      }
   };

   return (
      <div className={styles.advantagesContainer}>
         <div className={styles.topContainer}>
            <p className="tagline-text">ADVANTAGES</p>
            <h2>Precision, Durability, and Trust</h2>
         </div>
         <div className={styles.bottomContainer}>
            <div className={styles.leftContainer}>
               {AdvantagesEnum.map((adv, index) => (
                  <Advantage
                     key={index}
                     ref={(el) => (cardRefs.current[index] = el)}
                     {...adv}
                     isActive={activeIndex === index}
                  />
               ))}
            </div>
            <div className={styles.rightContainer}>
               <div className={styles.imgContainer}>
                  <img
                     src={isMobile ? ComputerImg1 : getComputerImage()}
                     alt="Lizard Computer"
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
