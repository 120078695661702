import React from "react";
import styles from "./styles.module.css";
import Button, { ButtonTypes } from "../../button";
import HQImg from "../../../images/hqImg.png";

export default function AboutCard({ handleOpenPopup }) {
   const scrollToSection = () => {
      const section = document.getElementById("contactSection");
      const offset = -50;
      const sectionPosition =
         section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
         top: sectionPosition + offset,
         behavior: "smooth",
      });
   };

   return (
      <div className={styles.container}>
         <div className={styles.leftContainer}>
            <div className={styles.headingContainer}>
               <p className="tagline-text">Lizar systems</p>
               <h2>Who are we?</h2>
            </div>
            <p className={styles.text}>
               LIZAR Systems is a Govtech European company with production
               sites, R&D and Excellence centers in Portugal (HQ), France and
               Latin America. Our venture founded in 2023 to disrupt the ID
               market with its advanced laser engraving and imaging technologies
               with the aim to deploy them worldwide. 
            </p>
            <div className={styles.buttonsContainer}>
               <Button variant={ButtonTypes.PRIMARY} onClick={scrollToSection}>
                  Contact us
               </Button>
               <Button
                  variant={ButtonTypes.SECONDARY}
                  onClick={handleOpenPopup}
               >
                  Watch video
               </Button>
            </div>
         </div>
         <div className={styles.imgContainer}>
            <img src={HQImg} alt="Headquarters" />
         </div>
      </div>
   );
}
