import React from "react";
import styles from "./styles.module.css";
import LogoSVG from "../../../images/logo.svg";
import DracoSVG from "../../../images/draco.svg";
import lizardVideo from "../../../videos/lizard.mp4";
import Button, { ButtonTypes } from "../../button";
import { ReactComponent as FamocoSVG } from "../../../images/famoco.svg";

export default function LandingContent({ handleOpenPopup }) {
   const scrollToSection = () => {
      document.getElementById("mainSection").scrollIntoView({
         behavior: "smooth",
         block: "start",
      });
   };

   return (
      <div className={styles.landingContainer}>
         <video autoPlay loop muted className={styles.videoBackground}>
            <source src={lizardVideo} type="video/mp4" />
            Your browser does not support the video tag.
         </video>
         <div className={styles.landingContent}>
            <div className={styles.logoWrapper}>
               <img className={styles.logo} src={LogoSVG} alt="Logo" />
               <div className={styles.poweredBy}>
                  <p>Powered by</p>
                  <FamocoSVG />
               </div>
            </div>
            <div className={styles.middleContainer}>
               <div className={styles.textContainer}>
                  <img src={DracoSVG} alt="Draco" />
                  <p className="body-lg">Shaping Identity</p>
               </div>
               <div className={styles.buttonsContainer}>
                  <Button
                     variant={ButtonTypes.PRIMARY}
                     onClick={scrollToSection}
                  >
                     Get started
                  </Button>
                  <Button
                     variant={ButtonTypes.SECONDARY}
                     onClick={handleOpenPopup}
                  >
                     Watch video
                  </Button>
               </div>
            </div>
         </div>
      </div>
   );
}
