import { useState, useEffect } from "react";

export function useIsMobile(breakpoint = 600) {
   const [isMobile, setIsMobile] = useState(false);

   useEffect(() => {
      const updateIsMobile = () => {
         setIsMobile(window.innerWidth <= breakpoint);
      };

      updateIsMobile(); // Initial check
      window.addEventListener("resize", updateIsMobile);

      return () => {
         window.removeEventListener("resize", updateIsMobile);
      };
   }, [breakpoint]);

   return isMobile;
}
